#pollverse-voices-compare {
  .section-title {
    padding: 20px 0 10px 0;
  }

  select {
    padding-block: 13px;
  }

  .pollverse-voices-body {
    width: 100%;
    margin: auto;
    max-width: 80rem;
  }

  .pollverse-voices-summary {
    background-color: #1a3a4b;
    padding: 15px 30px;
    border-radius: 14px;
    margin-bottom: 20px;
  }

  .pollverse-response-card-wrapper {
    display: flex;
    // flex-direction: column;
    gap: 20px;

    .pollverse-response-card {
      box-sizing: border-box;
      margin: auto;

      .title {
        font-size: 150%;
        margin-bottom: 15px;
      }
    }
  }
}
