.hs-page-container {
  position: relative;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;

  color: #78d2f1;
  font-family: Tomorrow;
}
