.network-map-screen-container {
  .network-map-body {
    background: rgba(8, 20, 27, 0.6);
    border: 1px solid #1c3a4a;
    border-radius: 20px;
    padding: 20px;
    // Needed because for some reason that I don't understand, the Regraph canvas is offset down a bit and display outside the body.
    overflow: hidden;

    .search-bar {
      position: relative;
      float: left;
      display: inline-block;
      width: 300px;
      background: #08151d;
      border: 1px solid #0e2338;
      border-radius: 10px;
      padding: 10px 20px;
      img {
        margin-right: 15px;
      }

      input {
        color: white;
      }
      input::placeholder {
        color: #8d9ca6;
      }
      .suggestion-list {
        position: absolute;
        z-index: 2;
        top: 40px;
        background: #08151d;
        border: 1px solid #0e2338;
        border-radius: 10px;
        padding: 10px 0;
        min-width: 200px;

        .suggestion-item {
          margin: 10px 0;
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
    .status {
      display: inline-block;
      float: right;
      position: relative;

      .progress-status {
        display: inline-block;
        .slider-wrapper {
          display: inline-block;
          width: 100px;
        }
        .circle-wrapper {
          display: inline-block;
          margin-left: 20px;
          .circle-progress {
            display: inline-block;
            position: relative;
            top: 10px;
            margin-right: 5px;
          }
        }
      }
      .users-status {
        display: inline-block;
        width: 100px;
        margin-left: 40px;
        img {
          margin-right: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
  }
}

.pathways-screen-container {
  .pathways-tabs {
    display: block;
    height: 63px;
    background: rgba(8, 20, 27, 0.6);
    border: 1px solid #1c3a4a;
    border-radius: 20px 20px 0 0;
    position: relative;

    .pathways-client-label {
      position: absolute;
      top: 25px;
      right: 20px;
      color: white;
    }
    .pathways-tab {
      padding-top: 15px;
      height: 48px;
      display: inline-block;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-left: 30px;
      color: #8d9ca6;
      cursor: pointer;

      .pathways-tab-total-accounts {
        display: inline-block;
        min-width: 30px;
        text-align: center;
        background: #10222e;
        border-radius: 20px;
        padding: 8px;
        margin-left: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d9ca6;
      }

      &.active {
        border-bottom: 2px solid #78d2f1;
        color: #78d2f1;
        .pathways-tab-total-accounts {
          background: #78d2f1;
          color: #08161f;
        }
      }
    }
  }
}

.module-footnote {
  margin-top: 10px;
  float: right;
  text-align: right;
  font-size: small;
  color: #8d9ca6;
  img {
    margin-right: 5px;
  }
}

.conversational-matrix-container {
  display: flex;
  // height: calc(100vh - 213px);
  flex-flow: column;
  .tabs-wrapper {
    background: rgba(8, 20, 27, 0.6);
    border: 1px solid #1c3a4a;
    border-radius: 20px 20px 0 0;
    border-bottom: 0;
    display: flex;
    padding: 40px 70px 0 40px;
    justify-content: space-between;
    align-items: baseline;

    .matrix-sort-title {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      color: #8d9ca6;
      .title-text {
        display: flex;
        align-items: center;
        span {
          text-transform: uppercase;
          margin-left: 5px;
        }

        path {
          fill: #8d9ca6 !important;
        }
      }
      .reset-sorting-wrapper {
        justify-content: flex-end;
        display: flex;
        .reset-sorting {
          float: right;
          padding: 8px 25px;
          border: 2px solid #78d2f1;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .matrix-border {
    width: 100%;
    background: rgba(8, 20, 27, 0.6);
    border: 1px solid #1c3a4a;
    border-top: 0;
    border-radius: 0 0 20px 20px;
    box-sizing: border-box;
    padding: 0 40px 20px 40px;
    position: relative;
    .p {
      font-size: x-large;
      justify-content: center;
      display: flex;
      margin-top: 100px;
    }
    .matrix-wrapper {
      // height: calc(100% - 16px);
      padding-bottom: 0;
      justify-content: space-around;
      .matrix-table-wrapper {
        // width: fit-content;
        max-width: 100%;
        // height: 100%;
        .sticky-table {
          .sticky-table-row {
            .sticky-table-cell {
              padding: 0 !important;
              background: transparent;
            }
            &:first-of-type {
              .sticky-table-cell {
                max-width: 150px;
                min-width: 150px;
                margin: 0 1px;
                .icon-header {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  background: #1e4552;
                  border-radius: 20px 20px 0 0;
                  padding: 20px;
                  height: 35px;
                  width: -webkit-fill-available;
                  border-bottom: 4px solid #78d2f1;
                  div {
                    margin-right: 5px;
                    font-size: 13px;
                    text-align: left;
                    &.active {
                      color: #78d2f1;
                    }
                  }
                  img {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .matrix-table {
          height: 100%;
          width: 100%;
          .header {
            display: flex;
            height: max-content;
            width: 100%;
            overflow: hidden;
            .header-item {
              max-width: 150px;
              min-width: 150px;
              margin: 0 1px;
              .icon-header {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: white;
                background: #1e4552;
                border-radius: 20px 20px 0 0;
                padding: 20px;
                height: 35px;
                width: -webkit-fill-available;
                border-bottom: 4px solid #78d2f1;
                div {
                  margin-right: 5px;
                  font-size: 13px;
                  text-align: left;
                  &.active {
                    color: #78d2f1;
                  }
                }
                img {
                  cursor: pointer;
                }
              }
            }
          }
          .body {
            height: calc(100% - 80px);
            width: 100%;
            overflow: auto;
            position: relative;
            .body-row {
              display: flex;
              .body-item {
                max-width: 150px;
                min-width: 150px;
                color: white;
                padding: 20px 5px;
                text-align: center;
                cursor: pointer;
                box-sizing: border-box;
                margin: 0 1px;
              }
              &:nth-of-type(2n + 1) {
                .body-item {
                  background: #2b5b69;
                }
              }
              &:nth-of-type(2n) {
                .body-item {
                  background: #4a7886;
                }
              }
              &:hover {
                background: #7399a5;
                .body-item {
                  background: #7399a5;
                }
              }
            }
          }
        }
      }
    }
  }
}

.hs-card-popup {
  position: absolute;

  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    color: #1c3d4d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}

.hs-account-card {
  max-width: 336px;
  color: #1c3d4d;
  border-radius: 20px;
  font-family: Tomorrow;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  overflow: hidden;

  .hs-account-card-body {
    position: relative;
    padding: 21px 25px;
    box-sizing: border-box;
    flex: 4 1;
    padding-bottom: 70px;

    .account-name {
      font-family: TomorrowBold;
      font-size: 26px;
      font-weight: 700;
      line-height: 31px;
      overflow-wrap: anywhere;
    }
    .account-card-extra-button {
      float: right;
      cursor: pointer;
      width: 38px;
      height: 33px;
      margin: 8px 0;
      color: black;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      background: white;
      border-radius: 14px;
    }
    .account-progress-status {
      margin-top: 8px;
      display: flex;
      align-items: center;
      .circle-progress {
        height: 30px;
      }
      span {
        margin-left: 8px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .message-wrapper {
      width: 289px;
      max-height: 304px;
      margin-top: 20px;

      &.chart {
        max-height: 360px;
      }
      .message-item {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.7);
        padding: 8px;
        cursor: pointer;
        overflow: hidden;
        &.message-item-highlighted {
          border: solid 5px #78d2f1;
        }
        .message-header {
          display: flex;
          align-items: center;
          .message-avatar {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: #78d2f1;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
          }
        }
        .message-content {
          margin-top: 11px;
          font-size: 12px;
          max-height: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      .message-item:not(:first-of-type) {
        margin-top: 12px;
      }
    }
    .account-card-footer {
      display: block;
      position: absolute;
      bottom: 15px;
      left: 25px;
      right: 25px;

      .social-links {
        display: inline-block;
        font-size: 30px;
        min-height: 36px;
        height: 36px;

        a {
          color: white;
          margin-right: 24px;
        }
      }
      .account-card-star {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
        color: white;
        &.star {
          color: #ffd74a;
        }
      }
    }
  }
  .button-analysis {
    flex: 0 1 47px;
    cursor: pointer;
    width: 100%;
    height: 47px;
    border-radius: 0 0 21px 21px;
    background: white;
    //position: absolute;
    //bottom: 0;
    //left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      background: grey;
    }
    .button-text {
      font-family: "TomorrowBold";
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      span {
        margin-right: 10px;
      }
    }
  }
}

.hs-starred-accounts-screen {
  .hs-starred-accounts {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .hs-account-card {
      .message-wrapper {
        height: 320px;
        max-height: 320px;
      }
    }
  }
}

.hcp-popup {
  padding: 50px;
  padding-top: 150px;
  .hcp-popup-profile {
    display: flex;
    justify-content: center;
    align-items: center;

    .hcp-popup-profile-picture {
      border-radius: 50%;
      background-color: white;
      width: 90px;
      height: 90px;
      margin-right: 50px;
    }
    .hcp-popup-profile-info {
      font-size: 25px;
      font-weight: 400;
      line-height: 30px;
      color: white;

      .hcp-popup-profile-name {
        font-weight: 600;
      }
      .hcp-popup-profile-npi {
        display: inline-block;
        background-color: #b7ecff;
        border-radius: 3px;
        color: black;
        font-size: 18px;
        padding: 0 10px;
      }
      .hcp-popup-profile-specialty {
      }
      .hcp-popup-profile-location {
        text-transform: uppercase;
      }
    }
  }

  .hcp-popup-payments {
    background-color: rgba(8, 20, 27, 0.6);
    border-radius: 20px;
    width: 700px;
    margin: auto;
    margin-top: 60px;
    padding-bottom: 20px;

    color: #78d2f1;
    .hcp-popup-payments-header {
      font-weight: 600;
      text-transform: uppercase;
      padding: 15px 30px;
      border-bottom: 1px solid #78d2f1;
      .hcp-popup-payments-total {
        float: right;
      }
    }
    .hcp-popup-payments-list {
      color: white;
      background: #18323f;
      border-radius: 10px;
      margin: 20px;
      padding: 20px 0;
      max-height: 300px;

      .hcp-popup-payments-row {
        position: relative;
        display: flex;
        height: 40px;
        margin-bottom: 20px;

        .hcp-popup-payments-row-percent {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #487f9d;
          height: 100%;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .hcp-popup-payments-row-amount {
          z-index: 1;
          display: flex;
          align-items: center;
          width: 120px;
          padding-left: 20px;
        }
        .hcp-popup-payments-row-company {
          z-index: 1;
          flex: 1;
          display: flex;
          align-items: center;
        }
      }
    }
    .hcp-popup-payments-footer {
      text-align: right;
      font-size: 12px;
      font-style: italic;
      margin-right: 20px;
    }
  }
}
