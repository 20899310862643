.hs-menu-container {
  position: relative;
  top: 50px;
  margin: 0 50px;
  min-height: 550px;
  padding: 0;
  padding-bottom: 40px;
  background: #112936;
  border-radius: 15px;

  .hs-menu-header {
    height: 60px;
    border-bottom: 1px solid #1c3a4a;
    padding-left: 25px;
  }

  .hs-menu-modules {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    flex-wrap: wrap;

    .hs-menu-module {
      position: relative;
      margin: 0;
      padding-inline: 35px;
      width: 100%;
      min-width: 200px;
      max-width: calc(25% - 70px);
      svg {
        float: left;
        display: block;
      }
      h2 {
        display: block;
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
        padding-left: 35px;
        padding-right: 5px;
        font-family: Gilroy;
        text-transform: uppercase;
      }
      a {
        outline: none;
        color: white;
        text-decoration: none;
      }
      .hs-menu-module-hr {
        position: relative;
        hr {
          border: 1px solid #78d2f1;
          margin-bottom: 40px;
        }

        .circle {
          position: absolute;
          top: -4px;
          right: -4px;
          height: 10px;
          width: 10px;
          background-color: #78d2f1;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
    .hs-menu-module:not(:first-child) {
      box-shadow: -1px 0 0 0 rgba(120, 210, 241, 0.4);
    }
    @media screen and (max-width: 1279px) {
      .hs-menu-module {
        max-width: calc(50% - 70px);
        flex-grow: 1;
      }

      .hs-menu-module:nth-child(odd) {
        box-shadow: 0 0 0 0;
      }
    }
    @media screen and (max-width: 719px) {
      .hs-menu-module {
        box-shadow: 0 0 0 0 !important;
      }
    }
    .hs-menu-submodules {
      padding-left: 10px;
      .hs-menu-submodule {
        margin: 30px 0;
        svg {
          margin-right: 10px;
          float: left;
        }
        a {
          outline: none;
          color: #78d2f1;
          text-decoration: none;
        }
        h4,
        .hs-menu-submodule-description {
          margin: 0;
          margin-left: 40px;
          margin-bottom: 10px;
        }
        .hs-menu-submodule-description {
          color: white;
        }
        &.disabled {
          color: #8d9ca6;
          a,
          h4,
          .hs-menu-submodule-description {
            color: #8d9ca6;
            cursor: unset;
          }
        }
      }
    }
  }
}
