.pollverse-map-container {
  .pollverse-response-card-name {
    margin-top: 0 !important;
  }

  .pollverse-response-card-wrapper {
    position: absolute;
    top: 0;
    left: 100px;
    max-width: 80%;
    display: flex;
  }
}
