.atlas-calendar-select-container {
  .atlas-calendar-select-control {
    border: 1px solid #1c3a4a;
    border-radius: 14px;
    color: #78d2f1;
    padding: 10px 20px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    span {
      padding: 0 17px;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
    }
  }
  .atlas-calendar-select-menu {
    z-index: 1;
    background: #092833;
    border-radius: 0 20px 20px 0;
    display: flex;
    right: 0;
    padding: 40px 56px 34px 31px;
    position: absolute;
    top: 50px;

    .atlas-calendar-select-menu-part:not(:first-child) {
      margin-left: 55px;
    }
    .atlas-calendar-select-menu-part {
      width: 240px;
      .atlas-calendar-select-menu-part-header {
        display: flex;
        justify-content: space-between;
        label {
          align-self: center;
          font-size: 18px;
          margin-right: 60px;
          padding-left: 11px;
        }
      }

      .cmc-table {
        padding: 22px 0;
        .cmc-row {
          display: flex;
          justify-content: space-between;
          .cmc-item {
            cursor: pointer;
            padding: 11px;
            &.active {
              background: #78d2f1;
              border-radius: 11px;
              color: #092833;
            }
            &.invalid {
              color: #878787;
              cursor: default;
            }
          }
        }
      }
    }
    .atlas-calendar-select-menu-ok {
      background: #78d2f1;
      border: none;
      border-radius: 8px;
      bottom: 10px;
      padding: 8px 20px;
      position: absolute;
      right: 54px;
      cursor: pointer;
    }
    .dd-wrapper {
      cursor: pointer;
      z-index: 10;
      width: 110px;
      .dd-header {
        display: flex;
        justify-content: space-evenly;
        border-bottom: 1px solid #78d2f1;
        font-size: 18px;
        padding: 10px;
      }
      .dd-list {
        position: absolute;
        width: 110px;
        border: 1px solid #78d2f129;
        &-item {
          padding: 10px;
          text-align: center;
          font-size: 18px;
          background: #092833;
          &:hover {
            background: #78d2f1;
            color: #092833;
          }
        }
      }
    }
  }
}
