.charts-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;

  .chart-widget {
    background: rgba(8, 20, 27, 0.6);
    border: 1px solid #1c3a4a;
    border-radius: 20px;
    padding: 40px;
    color: white;

    .chart-widget-header {
      position: relative;
      margin-bottom: 15px;
      .chart-widget-header-title {
        display: inline-block;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        text-transform: uppercase;
      }
    }
  }
  .chart-widget-full {
    flex-grow: 2;
    width: 100%;
  }
  .chart-widget-half {
    flex-grow: 1;
  }
}
