.voices2-container {
  display: flex;
  flex-flow: row;
  gap: 30px;

  padding: 20px 40px;

  .voices2-sidebar {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 360px;
    flex-basis: 360px;
    flex-shrink: 0;

    .voices2-sidebar-tabs {
      background-color: #040e18;
      border-radius: 15px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      gap: 10px;
      padding: 15px 15px;

      .voices2-sidebar-tab {
        background-color: #ffffff03;
        color: #8d9ca6;
        height: 42px;
        border-radius: 14px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        &.active {
          background-color: #78d2f116;
          color: #78d2f1;
        }
      }
    }

    .voices2-sidebar-calendar {
      position: relative;
      text-align: center;
      border-radius: 14px;
      background-color: #112936;
      padding: 10px;

      .atlas-calendar-select-container {
        .atlas-calendar-select-menu {
          right: unset;
          left: 0;
        }
      }
    }

    .voices2-sidebar-audiences,
    .voices2-sidebar-bots {
      border-radius: 14px;
      background-color: #112936;
      padding: 10px 10px;

      .voices2-sidebar-bot {
        color: white;
        padding: 20px 0;
        padding-left: 40px;
        cursor: pointer;

        border-bottom: 1px solid #1c3a4a;

        &.selected {
          color: #78d2f1;
        }
      }
    }
  }
  .voices2-body {
    padding: 30px;
    border-radius: 14px;
    background-color: #112936;
    flex-grow: 1;

    .voices2-body-lead {
      color: white;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .voices2-question-input {
      background-color: #08141ba0;
      border: 1px solid #1c3a4a;
      border-radius: 14px;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      height: 44px;

      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      input {
        background: transparent;
        outline: none;
        border: none;
        color: white;
        flex-grow: 1;
      }

      .voices2-question-submit {
        border-radius: 14px;
        flex-basis: 30px;
        height: 30px;
        background-color: #78d2f116;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .voices2-body-answer {
      color: #889ba7;
      margin-top: 40px;
      padding: 20px;
      background-color: #1a3a4b;
      border-radius: 14px;
      min-height: 100px;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;

      h4 {
        display: block;
        font-size: 22px;
        font-weight: 500;
        color: white;
        margin: 10px 0;
      }

      .voices2-body-answer-show-tweets {
        margin-top: 10px;
        text-align: right;
      }
    }
  }

  .voices2-tweets-placeholder {
    width: 400px;
    flex-basis: 400px;
    flex-shrink: 0;

    .voices2-tweets {
      background-color: #08141ba0;
      border: 1px solid #1c3a4a;
      border-radius: 20px;
      padding: 20px;
      .voices2-tweets-label {
        color: white;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
}
