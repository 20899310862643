.listening-summary-container {
}

.listening-line-chart-widget {
  .listening-line-chart-header {
    .listening-line-chart-header-note {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #8d9ca6;
      em {
        font-style: normal;
        font-weight: 600;
        color: #78d2f1;
      }
    }
  }
}

.listening-benchmarking-container {
  position: relative;
}

.listening-benchmarking-select-series-container {
  margin: 20px 0 30px 0;
  color: white;
  font-size: 20px;

  .listening-benchmarking-select-series-header {
    background: #112936;
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 30px;

    .listening-benchmarking-select-series-text {
      cursor: pointer;
    }
    .listening-benchmarking-select-series-item {
      background: #1a394a;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
    }
    .listening-benchmarking-select-series-item:first-child {
      margin-left: 20px;
    }
    svg {
      margin-left: 20px;
      cursor: pointer;
    }

    &.folded {
      border-radius: 15px;
    }

    &.unfolded {
      border-radius: 15px 15px 0 0;
      border: 1px solid #1c3a4a;
    }
  }
  .listening-benchmarking-select-series-body {
    padding: 30px 30px;
    min-height: 120px;
    background: #112936;
    border-radius: 0 0 15px 15px;
    .listening-benchmarking-select-series-create {
      color: #78d2f1;
      margin-bottom: 15px;
      cursor: pointer;
    }
    .listening-benchmarking-select-series-item {
      margin: 10px 0;
      input {
        margin-right: 10px;
        cursor: pointer;
      }
      svg {
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .listening-benchmarking-select-series-definition {
      .input-name {
        display: block;
        background: none;
        color: white;
        height: 30px;
        width: 300px;
        margin: 0 10px;
        padding: 5px 0 5px 15px;
        outline: none;
        border: 1px solid #1c3a4a;
        border-radius: 14px;
        font-size: 20px;
        line-height: 24px;
      }
      .input-name::placeholder {
        color: white;
        font-size: 20px;
      }
    }
  }
}

.listening-content-container {
  .twitter-tweet {
    margin: 30px auto;
  }

  .listening-content-hashtag-filter {
    label {
      color: white;
    }
    input {
      margin-left: 20px;
      width: 100px;
    }
  }

  .listening-content-message {
    display: block;
    font-size: 18px;
    padding: 30px;
    background: #1a3a4b;
    color: white;
    border-radius: 14px;
    width: 700px;
    min-height: 100px;
    margin: 20px auto;
    text-decoration: none;

    .listening-content-message-date {
      float: right;
      color: #8d9ca6;
    }
    .listening-content-message-author {
      margin-bottom: 20px;
      color: #8d9ca6;
    }
    .listening-content-message-text {
    }
  }
}
