.pollverse-voices-screen-container {
  background: #112936;
  border-radius: 20px;
  padding: 50px;
  color: white;
  min-height: 600px;
  margin-top: 20px;

  .pollverse-voices-welcome-text {
    text-align: center;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 22px;
    em {
      color: #78d2f1;
    }
  }

  .pollverse-voices-inspire-me {
    display: flex;
    justify-content: center;
    button {
      margin-bottom: 20px;
    }
  }

  .pollverse-voices-body {
    display: flex;
    margin: 0 80px;
    .pollverse-voices-question-answer {
      flex: 1 1 auto;
      margin: 0 auto;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      .pollverse-voices-question {
        width: 100%;
        display: flex;
        .pollverse-voices-question-input {
          resize: none;
          overflow: hidden;
          background: rgba(8, 20, 27, 0.6);
          border: 1px solid #1c3a4a;
          outline: none;
          border-radius: 14px;
          color: white;
          font-size: 20px;
          line-height: 26px;
          padding-inline: 30px;
          padding-block: 15px;
          flex-grow: 1;
        }
        .pollverese-voices-question-input::placeholder {
          color: white;
        }
        .pollverse-voices-question-submit {
          height: 50px;
          background: rgba(120, 210, 241, 0.1);
          border: 1px solid #1c3a4a;
          border-radius: 10px;
          color: #78d2f1;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          padding: 0 20px;
          margin-left: 20px;
          cursor: pointer;
          &.disabled {
            background: rgba(255, 255, 255, 0.03);
            color: #8d9ca6;
            cursor: unset;
          }
        }
      }
      .pollverse-voices-answer {
        font-size: 18px;
        padding: 30px;
        background: #1a3a4b;
        border-radius: 14px;
        min-height: 100px;
        margin: 20px 0;
        display: flex;
        align-items: center;
        .pollverse-voices-answer-text {
          flex: 1 1 auto;
          padding-right: 10px;
        }
        .pollverse-voices-answer-star {
          flex: 0 0 50px;
          width: 50px;
          height: 50px;
          background: #0c1d26;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .pollverse-example-posts {
        font-size: 18px;
        p {
          margin-top: 50px;
          line-height: 22px;
          color: #8d9ca6;
        }
        .pollverse-voices-example-post {
          display: block;
          padding: 30px;
          background: #1a3a4b;
          border-radius: 14px;
          margin: 10px 0;
          text-decoration: none;
          color: white;
        }
      }
      .pollverse-voices-disclaimer {
        margin-top: 50px;
        font-size: smaller;
        font-style: italic;
        text-align: center;
      }
    }
  }
}

.pollverse-response-card-wrapper {
  .pollverse-response-card {
    background-color: #1a3a4b;
    border-radius: 14px;
    display: flex;
    padding: 20px;
    margin-block: 10px;
    border: 1px solid #112936;
    width: 100%;
    color: white;

    &.chosen {
      background-color: #193646;
      border: 1px solid #78d2f1;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    img {
      display: block;
      border-radius: 14px;
    }

    .pollverse-response-card-name {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 16px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    .pollverse-party-bubble {
      width: 12px;
      height: 12px;
      border-radius: 100%;

      &.democrat {
        background-color: blue;
      }

      &.republican {
        background-color: red;
      }
    }

    .pollverse-response-card-response {
      font-size: 16px;
      padding-block: 16px;
      padding-inline: 16px;
    }

    .pollverse-example-posts {
      padding-inline: 16px;
      padding-top: 12px;
      margin-top: auto;
    }
  }

  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    color: #1c3d4d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}

.pollverse-inspire-me-popup {
  padding: 20px;

  h3 {
    color: white;
    text-align: center;
    margin-top: 40px;
  }

  .select-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 125%;
    margin-bottom: 20px;
  }

  .pollverse-inspire-me-popup-topic {
    cursor: pointer;
    text-align: center;
    margin: 10px 0;
    span {
      text-decoration: underline;
      margin-right: 5px;
    }
    i {
      color: white;
      font-style: normal;
      font-size: 20px;
      position: relative;
      top: -5px;
    }
  }
  .pollverse-inspire-me-popup-questions {
    color: white;
    width: 700px;
    margin: auto;
    text-align: left;

    li {
      margin-bottom: 1rem;
      list-style: none;
      > div {
        display: flex;
        gap: 1rem;
        cursor: pointer;
        justify-content: space-between;
      }

      path {
        fill: hsl(200, 45%, 27%);
      }

      &:hover {
        path {
          fill: white;
        }
      }
    }
  }
}

.pollverse-compare-screen-container {
  margin-top: 20px;
  padding-bottom: 400px;

  h2 {
    text-align: center;
    margin-top: 50px;
  }

  .pollverse-compare-choose-instructions {
    color: white;
    margin-left: 10px;
    margin-bottom: 15px;
    font-size: 18px;
  }
  .pollverse-compare-choose-politician {
    background-color: #112936;
    border-radius: 14px;
    display: inline-block;
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #112936;

    &.chosen {
      background-color: #193646;
      border: 1px solid #78d2f1;
    }

    img {
      display: block;
      border-radius: 14px;
    }

    .pollverse-compare-choose-politician-name {
      margin-top: 15px;
      text-align: center;
      color: white;
      font-size: 14px;
    }
  }

  .pollverse-compare-selector-container {
    .pollverse-candidates-selection {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
      margin-block: 24px;

      > div {
        border: 1px solid #1c3a4a;
        padding: 8px 16px;
        border-radius: 16px;
        color: #8d9ca6;

        &.selected {
          color: #78d2f1;
          border-color: #78d2f1;
        }
      }
    }
    .pollverse-compare-compare-button {
      button {
        margin-bottom: 16px;

        &.inactive {
          background-color: #1c3a4a;
          color: #8d9ca6;
          cursor: initial;
        }
      }
    }
  }

  .pollverse-compare-select-topic {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .pollverse-compare-compare-button {
    margin-top: 20px;
    text-align: center;
  }

  .pollverse-compare-summary-container {
    color: white;
    .pollverse-compare-summary-heading {
      color: white;
      font-size: 18px;
    }
    .pollverse-compare-summary-text {
      padding: 30px;
      background-color: #08141b;
      border: solid 1px #1c3a4a;
      border-radius: 16px;
      margin: 20px 0;

      h3 {
        margin: 2rem 0 0.5rem 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.pollverse-compare-wrapper {
  position: relative;
  z-index: 0;
  border-radius: 14px;
  min-height: 100px;
  box-sizing: border-box;

  .pollverse-compare-row {
    display: flex;
    gap: 20px;

    &:first-child {
      padding-top: 30px;
    }

    .pollverse-politician-introduction {
      img {
        display: block;
        float: left;
        margin-right: 10px;
        margin-bottom: 30px;
        border-radius: 15px;
      }

      p {
        margin: 0;
      }
    }

    h3 {
      font-size: 22px;
    }
    img {
      display: block;
      float: left;
      margin-right: 10px;
      margin-bottom: 30px;
      border-radius: 15px;
    }

    h4 {
      font-size: 20px;
      font-weight: 500;
      clear: both;
      margin-bottom: 0;
    }

    &:not(:last-child) {
      .pollverse-compare-individual-interview {
        text-align: left;
        border-bottom: #78d2f138 1px solid;
      }
    }

    .pollverse-background-left {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(50% - 10px);
        height: 100%;
        background-color: #1a3a4b;
        z-index: -1;
        border-radius: 14px;
      }
    }

    .pollverse-background-right {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% + 10px);
        width: calc(50% - 10px);
        height: 100%;
        background-color: #1a3a4b;
        z-index: -1;
        border-radius: 14px;
      }
    }

    > div {
      width: 50%;
      margin-inline: 30px;
    }

    .pollverse-compare-individual-interview {
      .pollverse-compare-individual-interview-label {
        color: #8d9ca6;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      .pollverse-compare-individual-interview-sources-show {
        color: #78d2f1;
        font-size: 13px;
        margin: 10px 0;
        cursor: pointer;
      }
      .pollverse-compare-individual-interview-sources {
        padding: 10px;
        background: #112936;
        border-radius: 5px;
        font-size: 12px;
        margin-block: 10px;
        h6 {
          font-size: 14px;
          color: white;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          padding: 0;
        }
        a {
          display: block;
          font-size: 14px;
          line-height: 22px;
          height: 66px;
          max-height: 66px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: white;
          text-decoration: none;
          margin: 10px 0;

          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

.pollverse-map-container {
  position: relative;

  .pollverse-map {
    display: flex;
    justify-content: center;
    margin-top: 84px;
    margin-bottom: 24px;
  }

  .pollverse-map-labels {
    display: flex;
    gap: 32px;
    justify-content: center;
    flex-wrap: wrap;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .pollverse-label-bubble {
      width: 20px;
      height: 20px;
      border-radius: 100%;

      &.con {
        background-color: #cb1d1d;
      }

      &.neutral {
        background-color: #c6c627;
      }

      &.pro {
        background-color: #68c95e;
      }
    }
  }
}
