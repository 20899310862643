.hs-module-not-available {
  background: #112936;
  border-radius: 20px;
  padding: 50px;
  color: white;
  min-height: 170px;
  margin-top: 20px;

  text-align: center;
  font-weight: 400;
  font-size: 22px;
}
