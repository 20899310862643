#page-404 {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: center;
  align-items: center;

  .status {
    font-size: 400%;
    padding: 1rem 0;
  }

  .description {
    margin-bottom: 3rem;
  }
}
