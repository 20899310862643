.content-studio-email-generator-container {
  background: #112936;
  border-radius: 20px;
  color: white;
  min-height: 600px;
  margin-top: 20px;

  display: flex;
  flex-flow: row;

  .content-studio-email-generator-settings {
    width: 360px;
    text-align: left;
    padding: 40px;
    border-right: solid 1px #1c3a4a;

    label {
      display: block;
      margin-bottom: 10px;
      color: #8d9ca6;
    }

    .hs-input {
      box-sizing: border-box;
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }
    .hs-textarea {
      box-sizing: border-box;
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }

    .content-studio-email-generator-button {
      text-align: left;
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }

  .content-studio-email-generator-result {
    flex: 1;
    padding: 40px;

    label {
      display: block;
      margin-bottom: 20px;
      color: #8d9ca6;
    }

    button {
      margin-top: 20px;
    }
  }
}
