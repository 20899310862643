$primary-color: #78d2f1;
$dark-back: #78d2f129;
$highlight-back: #78d3f13a;

.hs-page-container {
  padding-bottom: 50px;
  .hs-header-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #040e18;
    border-bottom: 1px solid #1c3a4a;
    height: 100px;
    z-index: 2;

    .hs-header-logo-title {
      .hs-logo {
        display: block;
        cursor: pointer;
        display: inline-block;
      }
      h1 {
        display: inline-block;
        margin: 0;
        padding: 0;
        padding-left: 20px;
        cursor: pointer;
        position: relative;
        top: -7px;
        color: white;
        text-transform: uppercase;
        font-family: Gilroy;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
      }
    }
    .hs-header-menubar {
      position: relative;
      padding-left: 0;
      border-left: 1px solid #1c3a4a;

      svg {
        margin-left: 25px;
        cursor: pointer;
      }
      .hs-menu-settings {
        position: absolute;
        top: 50px;
        right: 0;
      }
      .hs-menu-dropdown {
        background: #78d2f11f;
        backdrop-filter: blur(37px);
        padding: 10px 0;
        border-radius: 20px;
        min-width: 250px;

        .hs-menu-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 15px 20px;
          color: white;
          &:hover {
            color: #1b3c4c;
            background: $primary-color;
          }
          img {
            width: 23px;
            height: 23px;
          }
          span {
            text-transform: uppercase;
            margin-left: 1rem;
          }
        }
        .separator {
          height: 1px;
          background: #5e5e5e;
          margin: 10px 0;
        }
      }
    }
  }
  @media only screen and (max-width: 2000px) {
    .hs-header-container {
      padding: 0 50px;
    }
  }
  @media only screen and (min-width: 2000px) {
    .hs-header-container {
      padding: 0 150px;
    }
  }
  @media only screen and (min-width: 2200px) {
    .hs-header-container {
      padding: 0 350px;
    }
  }
  .legend-container {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    .shown-menu {
      display: flex;
      width: 280px;
      justify-content: space-between;
      padding: 20px 40px;
      background: #ffffff29;
      backdrop-filter: blur(37px);
      border-radius: 0 20px 0 0;
      box-sizing: border-box;
      span {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .hidden-menu {
      background: #ffffff29;
      backdrop-filter: blur(44px);
      border-top-right-radius: 40px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1016px;
      padding: 25px 55px;
      .legend-indicator {
        text-align: center;
      }
      .symbol-container {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        .symbol {
          display: flex;
          align-items: center;
          .symbol-text {
            line-height: 26px;
            margin-left: 20px;
            .symbol-title {
              text-transform: uppercase;
              font-size: 16px;
            }
            .symbol-desc {
              color: white;
              font-size: 14px;
            }
          }
        }
      }
      .color-container {
        display: flex;
        justify-content: space-between;
        margin-top: 42px;
        padding-bottom: 20px;
        width: 100%;
        height: 56px;
        flex-wrap: wrap;
        overflow-y: auto;
        .color-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          min-width: 250px;
          .color-box {
            min-width: 32px;
            height: 32px;
            border-radius: 9px;
            margin-right: 11px;
          }
          .color-name {
            color: white;
          }
        }
      }
    }
  }
  .hs-content-container {
    position: relative;
    .chart-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  @media only screen and (max-width: 2000px) {
    .hs-content-container {
      padding: 0 0;
    }
  }
  @media only screen and (min-width: 2000px) and (max-width: 2200px) {
    .hs-content-container {
      padding: 0 100px;
    }
  }
  @media only screen and (min-width: 2200px) {
    .hs-content-container {
      padding: 0 300px;
    }
  }
  .load-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    z-index: 100;
    display: flex;
    justify-content: center;
    .load-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.atlas-input {
  color: $primary-color;
  background: transparent;
  border: none;
  font-family: Tomorrow;
  font-size: 15px;
  outline: none;
  &::placeholder {
    color: $primary-color;
  }
  &:focus {
    outline: none;
  }
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

.hs-dropdown {
  background: #112936;
  border: 1px solid #1c3a4a;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
  border-radius: 14px;
  z-index: 15;
  padding: 20px;
  display: inline-block;

  .hs-dropdown-item {
    cursor: pointer;
  }
}
