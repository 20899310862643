table.atlas-table.atlas-table-bordered-top {
  thead {
    th:first-child {
      border-radius: 20px 0px 0px 0px;
    }
    th:last-child {
      border-radius: 0px 20px 0px 0px;
    }
  }
}

table.atlas-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  thead {
    color: #8d9ca6;
    text-align: left;
    th {
      background: #112936;
      height: 63px;
      border-top: 1px solid #1c3a4a;
      border-bottom: 1px solid #1c3a4a;
      padding: 0 20px;
      .sort-arrows {
        margin-left: 10px;
        display: inline-flex;
        gap: 0.25rem;

        .ordered-by {
          fill: #78d2f1;
        }
      }
      &.sortable {
        cursor: pointer;
      }
    }
    th:first-child {
      //border-radius: 20px 0px 0px 0px;
      border-left: 1px solid #1c3a4a;
      padding-left: 20px;
    }
    th:last-child {
      //border-radius: 0px 20px 0px 0px;
      border-right: 1px solid #1c3a4a;
    }
  }

  tbody {
    color: white;
    td {
      padding: 20px;
      background: rgba(8, 20, 27, 0.6);
    }
    tr td:first-child {
      border-left: 1px solid #1c3a4a;
    }
    tr td:last-child {
      border-right: 1px solid #1c3a4a;
    }
    tr:last-child td {
      border-bottom: 1px solid #1c3a4a;
    }
    tr:last-child td:first-child {
      border-radius: 0 0 0 20px;
    }
    tr:last-child td:last-child {
      border-radius: 0 0 20px 0;
    }
  }
}
