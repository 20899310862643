body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Tomorrow";
  src: local("Tomorrow"),
    url("./assets/fonts/Tomorrow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowMedium";
  src: local("TomorrowMedium"),
    url("./assets/fonts/Tomorrow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowBold";
  src: local("TomorrowBold"),
    url("./assets/fonts/Tomorrow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "TomorrowSemiBold";
  src: local("TomorrowSemiBold"),
    url("./assets/fonts/Tomorrow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Regular.otf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModal__Content {
  label {
    color: #78d2f1;
    font-family: Tomorrow;
    text-transform: uppercase;
  }
  .textarea-wrapper {
    margin-top: 1rem;
    .contact-textarea {
      background: #0d1d28;
      color: white;
      font-family: Tomorrow;
      padding: 10px;
      width: 480px;
      height: 120px;
      border: 1px solid #78d2f1;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .button-wrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    .blue-button {
      margin: 0;
    }
  }
}
.hidden {
  display: none;
}

.hs-scrollable-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.hs-scrollable-container::-webkit-scrollbar-track {
  background: transparent;
}
.hs-scrollable-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}
.hs-scrollable-container::-webkit-scrollbar-corner {
  background: transparent;
}
.hs-scrollable-container {
  overflow-y: auto;
  overflow-x: hidden;
}
.hs-button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 50px;
  min-width: 150px;
  padding: 0 15px;
  font-family: inherit;

  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;

  &.primary {
    background: rgba(120, 210, 241, 0.1);
    color: #78d2f1;
  }

  &.secondary {
    background: transparent;
    border: 2px solid #1b3a49;
    color: white;

    &:hover {
      background: rgba(120, 210, 241, 0.1);
      color: #78d2f1;
    }
  }
}

.hs-input {
  overflow: hidden;
  background: rgba(8, 20, 27, 0.6);
  border: 1px solid #1c3a4a;
  outline: none;
  border-radius: 14px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  padding: 7px 20px;
  width: 240px;
}

.hs-textarea {
  display: block;
  width: 100%;
  height: 130px;
  resize: none;
  overflow: hidden;
  background: rgba(8, 20, 27, 0.6);
  border: 1px solid #1c3a4a;
  outline: none;
  border-radius: 14px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  padding: 7px 20px;
}

// TODO: remove once the conv matrix popup has been migrated to the account popup style.
.graph-popup {
  top: 0;
  left: 0;
  position: absolute;
  padding: 21px 25px;
  background: #7795fe;
  border-radius: 20px;
  color: #1c3d4d;
  font-family: Tomorrow;
  max-width: 336px;
  box-sizing: border-box;
  .account-name {
    font-family: TomorrowBold;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    overflow-wrap: anywhere;
  }
  .account-progress-status {
    margin-top: 8px;
    display: flex;
    align-items: center;
    .circle-progress {
      height: 30px;
    }
    span {
      margin-left: 8px;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .message-wrapper {
    width: 289px;
    max-height: 304px;
    margin-top: 20px;
    overflow-y: scroll;
    &.chart {
      max-height: 360px;
    }
    .message-item {
      display: block;
      text-decoration: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.7);
      padding: 8px;
      cursor: pointer;
      color: black;
      overflow: hidden;
      .message-header {
        display: flex;
        align-items: center;
        .message-avatar {
          width: 24px;
          height: 24px;
          background: white;
          border-radius: 12px;
          border: 1px solid #7795fe;
          margin-right: 8px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 12px;
          }
        }
        span {
          font-size: 12px;
        }
      }
      .message-content {
        margin-top: 11px;
        font-size: 12px;
        max-height: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .message-item:not(:first-of-type) {
      margin-top: 12px;
    }
  }
  .social-links {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0;
    min-height: 36px;
    a {
      color: white;
      margin-right: 24px;
    }
    &.aactive {
      margin-bottom: 47px;
    }
  }
  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #7795fe;
  }
  .popup-like {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 30px;
    color: white;
    &.star {
      color: #ffd74a;
    }
    &.aactive {
      margin-bottom: 47px;
    }
  }
  .button-analysis {
    cursor: pointer;
    width: 100%;
    height: 47px;
    border-radius: 0 0 21px 21px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      background: grey;
    }
    .button-text {
      font-family: "TomorrowBold";
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      span {
        margin-right: 10px;
      }
    }
  }
}

.hs-checkmark {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0px, -50%);
    height: 1.2rem;
    width: 1.2rem;
    background-color: transparent;
    border: 1px solid #1c3a4a;
    border-radius: 4px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:hover input ~ .checkmark {
    background-color: #78d2f1;
  }

  input:checked ~ .checkmark {
    background-color: #78d2f1;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
