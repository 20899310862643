.tabbed-content-container {
  position: relative;
  margin: 30px 50px;
  .tabbed-content-labels-wrapper {
    display: inline-flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    padding: 0 5px 10px 5px;

    > div:first-child {
      background-color: #040e18;
      border-radius: 15px;
    }

    a.tabbed-content-label {
      display: inline-block;
      color: #8d9ca6;
      text-decoration: none;
      background: rgba(255, 255, 255, 0.03);
      border-radius: 14px;
      cursor: pointer;
      padding: 8px 25px;
      margin: 10px 5px 10px 5px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      min-width: 100px;
      height: 26px;
      text-align: center;
      &.active {
        background: rgba(120, 210, 241, 0.1);
        color: #78d2f1;
        font-weight: 500;
      }
      &.disabled {
        cursor: unset;
      }
    }

    .tabbed-content-widget {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}
