.hs-fullscreen-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(120, 210, 241, 0.12);
  backdrop-filter: blur(150px);
  z-index: 5;
  .hs-fullscreen-popup-wrapper {
    position: absolute;
    top: 5%;
    left: 2%;
    height: 90%;
    width: 96%;
    background: #18323f;
    border-radius: 35px;
    border: 2px solid #78d2f1;

    .hs-fullscreen-popup-close {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
}
