.atlas-pagination-footer {
  position: relative;
  margin-top: 10px;
  height: 60px;
  background: #112936;
  border-radius: 15px;
  color: #8d9ca6;

  .atlas-pagination-footer-max-entries {
    position: absolute;
    left: 20px;
    top: 15px;
    .atlas-pagination-footer-max-entries-text {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .atlas-pagination-footer-pages {
    position: absolute;
    right: 20px;
    top: 20px;
    .atlas-pagination-footer-page {
      display: inline-block;
      text-align: center;
      width: 26px;
      height: 26px;
      cursor: pointer;
      &.active {
        color: black;
        background: #78d2f1;
        border-radius: 5px;
      }
    }
  }
}
