.pollverse-filters-bar {
  background: #112936;
  border-radius: 15px;
  margin: 20px 0 30px 0;
  color: white;

  .pollverse-filters-buttons {
    display: inline-flex;
    gap: 20px;
  }
}

.pollverse-filters-bar-select {
  display: inline-block;
  height: 42px;
  margin: 10px 10px;
}

.pollverse-filters-bar-wrapper .hs-checkmark {
  margin-left: 10px;
}
