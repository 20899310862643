.typing-wrapper {
  display: flex;
  margin-top: 1rem;

  > div {
    background-color: #1a3a4b;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    gap: 0.75rem;
    align-items: center;

    .typing-animation {
      display: flex;
      gap: 0.5rem;
      padding-top: 0.75rem;

      .typing__dot {
        width: 0.5rem;
        height: 0.5rem;
        background: white;
        border-radius: 50%;
        opacity: 0;
        scale: 1;
        animation: loadingScaleFade 1.3s infinite ease-in-out;
      }

      .typing__dot:nth-child(1) {
        animation-delay: 0s;
      }

      .typing__dot:nth-child(2) {
        animation-delay: 0.2s;
      }

      .typing__dot:nth-child(3) {
        animation-delay: 0.4s;
      }

      @keyframes loadingScaleFade {
        0% {
          opacity: 0.5;
          scale: 1;
        }
        30% {
          opacity: 0.8;
          scale: 1.2;
        }

        60% {
          opacity: 0.5;
          scale: 1;
        }

        100% {
          opacity: 0.5;
          scale: 1;
        }
      }
    }
  }
}
