.voices-saved-button {
  width: 160px;
  height: 56px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 14px;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #8d9ca6;
  cursor: pointer;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}

.voices-patients-screen-container {
  background: #112936;
  border-radius: 20px;
  padding: 50px;
  color: white;
  min-height: 600px;
  margin-top: 20px;

  .voices-patients-welcome-text {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 22px;
    em {
      color: #78d2f1;
    }
  }

  .voices-patients-body {
    display: flex;
    .voices-patients-question-answer {
      flex: 1 1 auto;
      margin: 0 auto;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      .voices-patients-question {
        width: 100%;
        display: flex;
        .voices-patients-question-input {
          resize: none;
          overflow: hidden;
          background: rgba(8, 20, 27, 0.6);
          border: 1px solid #1c3a4a;
          outline: none;
          border-radius: 14px;
          color: white;
          font-size: 20px;
          line-height: 26px;
          padding-inline: 30px;
          padding-block: 15px;
          flex-grow: 1;
        }
        .voices-patients-question-input::placeholder {
          color: white;
        }
        .voices-patients-question-submit {
          height: 50px;
          background: rgba(120, 210, 241, 0.1);
          border: 1px solid #1c3a4a;
          border-radius: 10px;
          color: #78d2f1;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          padding: 0 20px;
          margin-left: 20px;
          cursor: pointer;
          &.disabled {
            background: rgba(255, 255, 255, 0.03);
            color: #8d9ca6;
            cursor: unset;
          }
        }
      }
      .voices-patients-answer {
        font-size: 18px;
        padding: 30px;
        background: #1a3a4b;
        border-radius: 14px;
        min-height: 100px;
        margin: 20px 0;
        display: flex;
        align-items: center;
        .voices-patients-answer-text {
          flex: 1 1 auto;
          padding-right: 10px;
        }
        .voices-patients-answer-star {
          flex: 0 0 50px;
          width: 50px;
          height: 50px;
          background: #0c1d26;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .voices-patients-example-posts {
        font-size: 18px;
        p {
          margin-top: 50px;
          line-height: 22px;
          color: #8d9ca6;
        }
        .voices-patients-example-post {
          display: block;
          padding: 30px;
          background: #1a3a4b;
          border-radius: 14px;
          margin: 10px 0;
          text-decoration: none;
          color: white;
        }
      }
      .voices-patients-disclaimer {
        margin-top: 50px;
        font-size: smaller;
        font-style: italic;
        text-align: center;
      }
    }
    .voices-patients-body-left-margin,
    .voices-patients-inspire-me {
      flex: 0 0 28%;
    }

    .voices-patients-inspire-me {
      padding-left: 30px;
      p {
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 34px;
        margin: 0;
      }
      button {
        display: inline-block;
        height: 50px;
        min-width: 150px;
        margin-left: 20px;
        border: none;
        outline: none;
        background: rgba(120, 210, 241, 0.1);
        border-radius: 10px;
        cursor: pointer;
        color: #78d2f1;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      .voices-patients-inspire-me-themes {
        background: #1a3a4b;
        border-radius: 14px;
        padding: 30px;
        margin-top: 20px;
        .voices-patients-inspire-me-themes-lead {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #8d9ca6;
          margin-bottom: 20px;
        }
        .voices-patients-inspire-me-themes-theme {
          margin-bottom: 10px;
          a {
            color: #78d2f1;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.voices-saved-screen {
  padding: 40px;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: white;
  }

  .voices-saved-answers {
    display: flex;
    flex-wrap: wrap;

    .voices-saved-answer {
      flex: 0 0 480px;
      position: relative;
      display: inline-block;
      background: #112936;
      border-radius: 14px;
      padding: 30px;
      margin: 20px 0;
      margin-right: 30px;

      .voices-saved-answer-type {
        display: inline-block;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 34px;
        padding: 10px 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
      }
      .voices-saved-answer-answer {
        height: 500px;
        margin: 10px 0;
        &.expanded {
          height: 560px;
        }
      }
      .voices-saved-answer-date {
        position: relative;
        float: right;
        padding-top: 10px;
      }
      .voices-saved-answer-date,
      .voices-saved-answer-question-label,
      .voices-saved-answer-period-label,
      .voices-saved-answer-answer-label,
      .voices-saved-answer-posts-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d9ca6;
      }
      .voices-saved-answer-question-label,
      .voices-saved-answer-answer-label,
      .voices-saved-answer-period-label,
      .voices-saved-answer-posts-label {
        margin-top: 40px;
      }
      .voices-saved-answer-question-text,
      .voices-saved-answer-answer-text,
      .voices-saved-answer-period-text,
      .voices-saved-answer-post-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: white;
      }
      .voices-saved-answer-show-posts-container {
        height: 60px;
        .voices-saved-answer-show-posts {
          background: rgba(120, 210, 241, 0.1);
          display: inline-block;
          padding: 15px 20px;
          border-radius: 10px;
          //position: absolute;
          //bottom: 20px;
          //left: 30px;
          cursor: pointer;
        }
      }

      .voices-saved-answer-posts {
        .voices-saved-answer-post-text {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
