.events-calendar-container {
  background: #112936;
  border-radius: 20px;
  color: white;
  min-height: 600px;
  margin: 20px 50px;

  .events-calendar-header {
    width: 100%;
    height: 60px;
    padding: 20px;
    border-bottom: solid 1px #1c3a4a;

    display: flex;
    flex-flow: row;

    h2,
    .events-calendar-header-year {
      margin-top: 20px;
      margin-bottom: 0;
    }
    h2 {
      text-align: center;
      flex-grow: 1;
      color: #8d9ca6;
    }
  }

  .events-calendar-body {
    display: flex;
    flex-flow: row;

    .events-calendar-list-view {
      flex: 0 0 300px;
      border-right: solid 1px #1c3a4a;
      padding: 20px 30px 20px 10px;

      .events-calendar-list-item {
        background: #1a3a4b;
        color: white;
        border-radius: 10px;
        padding: 15px;
        margin: 10px 0;
        font-size: 14px;

        .events-calendar-list-item-color {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          display: inline-block;
          float: left;
          margin-right: 7px;
          margin-top: 7px;
        }

        .events-calendar-list-item-description {
          color: #8d9ca6;
          font-size: 12px;
        }
      }
    }
    .events-calendar-calendar-view {
      flex-grow: 1;

      .events-calendar-calendar-month {
        display: inline-block;
        margin: 10px;
        width: 300px;

        h3 {
          text-transform: uppercase;
          color: #8d9ca6;
        }

        .events-calendar-calendar-month-row {
          display: flex;
          width: 100%;
          flex-flow: row;
          justify-content: space-evenly;
          margin: 10px 0;

          .events-calendar-calendar-month-date {
            width: 30px;
            text-align: right;

            &.disabled {
              color: #8d9ca6;
            }
          }
        }
      }
    }
  }
}
