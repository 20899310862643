.aa-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(120, 210, 241, 0.12);
  backdrop-filter: blur(150px);
  z-index: 5;
  .border-wrapper {
    position: absolute;
    top: 5%;
    left: 2%;
    height: 90%;
    width: 96%;
    background: #18323f;
    border-radius: 35px;
    border: 2px solid #78d2f1;
    .aa-container {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      position: relative;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 30px 0;
        background-clip: padding-box;
      }
      .aa-header {
        height: 80px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-family: "TomorrowBold";
          font-size: 22px;
          line-height: 26px;
          text-transform: uppercase;
        }
        .header-icons {
          .header-export {
            margin-right: 40px;
          }
        }
      }
      .aa-body {
        .profile {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          margin-top: 30px;
          .photo {
            width: 80px;
            height: 80px;
            background: white;
            border-radius: 40px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 40px;
            }
          }
          .text {
            display: flex;
            align-items: flex-end;
            margin-left: 20px;
            .name {
              font-family: "Tomorrow";
              font-weight: 400;
              font-size: 25px;
              line-height: 30px;
            }
            .separator {
              width: 1px;
              height: 27px;
              background-color: white;
              margin: 0 20px;
            }
            .state {
              font-family: "Tomorrow";
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
        .infobox-wrapper {
          display: flex;
          width: 95%;
          margin: 30px auto;
          flex-wrap: wrap;
          justify-content: center;
          .info-box {
            width: calc(20% - 30px);
            background: rgba(1, 2, 2, 0.16);
            border-radius: 20px;
            margin: 15px;
            display: flex;
            flex-direction: column;
            &.double {
              width: calc(40% - 60px);
            }
            .info-header {
              padding: 15px 20px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #517688;
              img {
                width: 21px;
                height: 21px;
              }
              span {
                font-size: 14px;
                font-weight: 600;
                margin-left: 10px;
                text-transform: uppercase;
              }
            }
            .info-body {
              display: flex;
              &.common {
                flex-direction: column;
                max-height: 20vh;
                overflow-y: scroll;
              }
              &.general {
                flex-direction: column;
                padding: 10px 20px;
                flex: 1;
                justify-content: space-around;
              }
              .info-row {
                display: flex;
                padding: 0 20px;
                color: white;
                align-items: flex-start;
                .circle {
                  min-width: 16px;
                  min-height: 16px;
                  max-width: 16px;
                  max-height: 16px;
                  border-radius: 8px;
                  margin-top: 6px;
                }
                .text {
                  display: flex;
                  flex: 1;
                  justify-content: space-between;
                  margin-left: 15px;
                  .title {
                    font-family: "Tomorrow";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 30px;
                    overflow-wrap: anywhere;
                  }
                  .value {
                    font-family: "Tomorrow";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 30px;
                  }
                }
              }
              .doughnut-wrapper {
                height: 200px;
                margin-left: 20px;
                margin-top: 10px;
                width: 80%;
              }
              .rect-progress {
                min-height: 25px;
                background: rgba(120, 210, 241, 0.12);
                position: relative;
                border-radius: 0;
                .progress {
                  top: 0;
                  left: 0;
                  position: absolute;
                  background: rgba(120, 210, 241, 0.3);
                  height: 100%;
                  border-radius: 0;
                }
                .text-wrapper {
                  top: 0;
                  left: 0;
                  position: absolute;
                  padding: 0 10px;
                  .percent {
                    font-size: 12px;
                    font-weight: 700;
                    color: white;
                    width: 40px;
                    display: inline-block;
                  }
                  .text {
                    font-size: 12px;
                    font-weight: 500;
                    color: white;
                  }
                }
                &:first-of-type {
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  .progress {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                  }
                }
                &:last-of-type {
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  .progress {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                  }
                }
              }
              .round-progress {
                background: rgba(120, 210, 241, 0.12);
                border-radius: 43px;
                height: 30px;
                &:not(:last-of-type) {
                  margin-bottom: 15px;
                }
                position: relative;
                .progress {
                  top: 0;
                  left: 0;
                  position: absolute;
                  border-radius: 43px;
                  background: rgba(120, 210, 241, 0.3);
                  height: 100%;
                }
                span {
                  top: 6px;
                  left: 15px;
                  position: absolute;
                  color: #fff9f9;
                  font-size: 14px;
                  font-weight: bold;
                }
              }
              .body-right {
                width: 45%;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .right-pair {
                  .title {
                    font-size: 14px;
                    line-height: 30px;
                    font-weight: 700;
                    text-transform: uppercase;
                  }
                  .value {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 30px;
                    color: white;
                  }
                  &:not(:first-child) {
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
